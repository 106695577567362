import { formatDateToTurkish } from '@utils/date.helpers';

const RejectedBarcodeTable = ({ productList }) => {
  return (
    <div className="relative w-full">
      <div className="overflow-x-auto max-w-full bg-softRed">
        <div className="min-w-[1162px] w-full ">
          <table className=" w-full table-fixed">
            <thead>
              <tr className="flex gap-3 2xl:gap-3 3xl:gap-5 px-5 py-2 text-base 2xl:text-base 3xl:text-lg mt-4">
                <th className="w-[40%] 2xl:w-[35%] 3xl:w-[35%] text-start">
                  İlaç Karekod
                </th>
                <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                  Alış Tarihi
                </th>
                <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                  Satış Tarihi
                </th>
                <th className="w-[30%] 2xl:w-[35%] 3xl:w-[35%] text-start">
                  Reddedilme Sebebi
                </th>
              </tr>
            </thead>

            <tbody>
              {productList.map((product, index) => (
                <tr
                  key={product.barcode}
                  className={`${
                    index === 0 ? 'mt-2' : ''
                  } flex gap-3 2xl:gap-3 3xl:gap-5 px-5 mb-2 text-sm 2xl:text-sm 3xl:text-base whitespace-nowrap`}
                >
                  <td
                    className="w-[40%] 2xl:w-[35%] 3xl:w-[35%]"
                    title={product.barcode}
                  >
                    <span className="bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap text-sm">
                      {product.barcode}
                    </span>
                  </td>
                  <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                    {product?.product_detail?.pharmacyBuyDate
                      ? formatDateToTurkish(
                          +product.product_detail.pharmacyBuyDate
                        )
                      : '-'}
                  </td>
                  <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                    {product?.product_detail?.pharmacySaleDate
                      ? formatDateToTurkish(
                          +product.product_detail.pharmacySaleDate
                        )
                      : '-'}
                  </td>
                  <td className="w-[30%] 2xl:w-[35%] 3xl:w-[35%]">
                    {product?.product_problem?.note}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RejectedBarcodeTable;
