import { useGet, usePost } from '@src/api/methods';
import { queryClient } from '@src/App';
import { Button, message, Select } from 'antd';
import { useMemo, useState } from 'react';

const ApplicationWholesalerUpdate = ({
  companyId,
  requestId,
  wholesalerName,
  isChangeable
}) => {
  const [selectedWholesaler, setSelectedWholesaler] = useState(null);
  const [isWholesalerSelectClicked, setIsWholesalerSelectClicked] =
    useState(false);

  const { data, isLoading } = useGet(
    `/pharmacypages/companywholesaler/${companyId}`
  );
  const { mutate, isPending: isUpdateRequestWholesalerPending } =
    usePost(`request-wholesaler`);

  const wholesalerOptions = useMemo(() => {
    return data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((wholesaler) => ({
        value: wholesaler.wholesalerId,
        label: wholesaler.name
      }));
  }, [data]);

  const handleWholesalerSelectOnChange = (value) => {
    setSelectedWholesaler(value);
    if (!isWholesalerSelectClicked) setIsWholesalerSelectClicked(true);
  };

  const handleWholesalerSaveButtonOnClick = () => {
    setIsWholesalerSelectClicked(true);
    const wholesalerIdAndRequest = {
      wholesalerId: selectedWholesaler,
      requestId
    };
    mutate(wholesalerIdAndRequest, {
      onSuccess: (_, vairables) => {
        setSelectedWholesaler(vairables.wholesalerId);
        setIsWholesalerSelectClicked(false);
        void message.success('Depo başarıyla değiştirildi.');
        queryClient.invalidateQueries({
          queryKey: ['pharmacypages/requests']
        });
      },
      onError: () => {
        setSelectedWholesaler(null);
        void message.error(
          'Depo değiştirilirken bir hata oluştu. Lütfen tekrar deneyin.'
        );
      }
    });
  };

  return (
    <div className="flex flex-row ">
      <span className="flex items-center text-start w-40 h-11 pl-2 bg-lightPurple3 text-lg rounded-md mr-2 text-white">
        Depo Adı:
      </span>
      {isChangeable ? (
        <Select
          variant="filled"
          className="w-[825px] h-11"
          value={selectedWholesaler ?? wholesalerName}
          options={wholesalerOptions}
          onChange={handleWholesalerSelectOnChange}
          loading={isLoading}
        />
      ) : (
        <span className="bg-gray-100 flex items-center rounded-md px-2 py-1 text-lg">
          {wholesalerName}
        </span>
      )}

      {isWholesalerSelectClicked && (
        <Button
          type="primary"
          className="bg-lightPurple3 ml-2 h-11"
          onClick={handleWholesalerSaveButtonOnClick}
          loading={isUpdateRequestWholesalerPending}
        >
          Kaydet
        </Button>
      )}
    </div>
  );
};

export default ApplicationWholesalerUpdate;
