import { formatDateToTurkish } from '@utils/date.helpers';
import { formatTurkishCurrency } from '@utils/numberFormats';
const ModuleType = {
  STOCK: 'STOCK',
  KKI: 'KKI',
  PAP: 'PAP'
};

const AcceptedBarcodeTable = ({ productList, moduleType }) => {
  const decideTable = () => {
    // STOCK AND PAP
    if (moduleType !== ModuleType.KKI) {
      return (
        <div className="relative w-full">
          <div className="overflow-x-auto max-w-full bg-softGreen">
            <div className="min-w-[1162px] w-full">
              <table className=" w-full table-fixed">
                <thead>
                  <tr className="flex gap-3 2xl:gap-3 3xl:gap-5 px-5 py-2 text-base 2xl:text-base 3xl:text-lg mt-4">
                    <th className="w-[40%] 2xl:w-[35%] 3xl:w-[35%] text-start">
                      İlaç Karekod
                    </th>
                    <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                      Alış Tarihi
                    </th>
                    <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                      Satış Tarihi
                    </th>
                    <th className="w-[20%] 2xl:w-[20%] 3xl:w-[25%] text-start">
                      Tutar (KDV Hariç)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productList.map((product, index) => (
                    <tr
                      key={product.barcode}
                      className={`${
                        index === 0 ? 'mt-2' : ''
                      } flex gap-3 2xl:gap-3 3xl:gap-5 px-5 mb-2 text-sm 2xl:text-sm 3xl:text-base`}
                    >
                      <td
                        className="w-[40%] 2xl:w-[35%] 3xl:w-[35%]"
                        title={product.barcode}
                      >
                        <span className="bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap text-sm">
                          {product.barcode}
                        </span>
                      </td>
                      <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                        {product?.product_detail?.pharmacyBuyDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacyBuyDate
                            )
                          : '-'}
                      </td>
                      <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                        {product?.product_detail?.pharmacySaleDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacySaleDate
                            )
                          : '-'}
                      </td>
                      <td className="w-[20%] 2xl:w-[20%] 3xl:w-[25%]">
                        <span className="bg-green text-white rounded-md px-2 py-1">
                          {product?.paymentAmount
                            ? formatTurkishCurrency(product?.paymentAmount, 2)
                            : '-'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else {
      // KKI
      return (
        <div className="relative w-full">
          <div className="overflow-x-auto max-w-full bg-softGreen">
            <div className="min-w-[1362px] w-full">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="flex gap-2 3xl:gap-5 px-5 mb-2 text-base 3xl:text-lg mt-4 whitespace-nowrap">
                    <th className="w-[31%] 2xl:w-[31%] 3xl:w-[30%] text-start">
                      İlaç Karekod
                    </th>
                    <th className="w-[6%] 2xl:w-[6%] 3xl:w-[7%] text-start">
                      Alış Tarihi
                    </th>
                    <th className="w-[6%] 2xl:w-[6%] 3xl:w-[7%] text-start">
                      Satış Tarihi
                    </th>
                    <th className="w-[17%] 2xl:w-[17%] 3xl:w-[15%] text-start">
                      Satış Tipi
                    </th>
                    <th className="w-[27%] 2xl:w-[27%] 3xl:w-[33%] text-start">
                      Depo
                    </th>
                    <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] text-start">
                      Tutar
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {productList.map((product, index) => (
                    <tr
                      key={product.barcode}
                      className={`${
                        index === 0 ? 'mt-2' : ''
                      } flex gap-2 3xl:gap-5 px-5 mb-2 text-sm 3xl:text-base whitespace-nowrap`}
                    >
                      <td
                        className="w-[31%] 2xl:w-[31%] 3xl:w-[30%]"
                        title={product.barcode}
                      >
                        <span className="bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap text-sm">
                          {product.barcode}
                        </span>
                      </td>
                      <td className="w-[6%] 2xl:w-[6%] 3xl:w-[7%]">
                        {product?.product_detail?.pharmacyBuyDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacyBuyDate
                            )
                          : '-'}
                      </td>
                      <td className="w-[6%] 2xl:w-[6%] 3xl:w-[7%]">
                        {product?.product_detail?.pharmacySaleDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacySaleDate
                            )
                          : '-'}
                      </td>
                      <td
                        className="w-[17%] 2xl:w-[17%] 3xl:w-[15%] overflow-hidden text-ellipsis whitespace-nowrap"
                        title={
                          product?.product_detail?.pharmacySaleToName ?? '-'
                        }
                      >
                        {product?.product_detail?.pharmacySaleToName ?? '-'}
                      </td>
                      <td
                        className="w-[27%] 2xl:w-[27%] 3xl:w-[33%] overflow-hidden text-ellipsis whitespace-nowrap"
                        title={
                          product?.wholesaler ? product.wholesaler?.name : '-'
                        }
                      >
                        {product?.wholesaler ? product.wholesaler?.name : '-'}
                      </td>
                      <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%]">
                        <span className="bg-green text-white rounded-md px-2 py-1">
                          {product?.paymentAmount
                            ? formatTurkishCurrency(product?.paymentAmount, 2)
                            : '-'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  };

  return decideTable();
};

export default AcceptedBarcodeTable;
