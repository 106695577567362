import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

const IntercomInitializer = () => {
  const auth = useSelector((state) => state.auth);
  const { user, role } = auth;

  const { boot, update, shutdown } = useIntercom();

  useEffect(() => {
    const initializeIntercom = () => {
      if (user) {
        const userData = {
          ...user,
          customAttributes: {
            verifiedgln: user.gln
          }
        };

        if (role && role !== 'Worker') {
          boot({ ...userData, hideDefaultLauncher: false });
        } else if (role && role === 'Worker') {
          shutdown();
        }
      } else {
        boot({ hideDefaultLauncher: false });
      }
    };

    initializeIntercom();

    return () => {
      shutdown();
    };
  }, [role, user, boot, update, shutdown]);

  return null;
};

export default IntercomInitializer;
