import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import {
  addLocalOffsetToDateObject,
  formatDateToUTCString
} from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import { axios_post_download } from '@src/utils/rest_axios';
import { axios_get } from '@utils/rest_axios';
import { statuses } from '@utils/staticJsonObjects/status';
import { Button, DatePicker, Form, Input, Select, Spin } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import FormCityItem from '../PharmacyRequestsComponents/FormCityComponent';
import './StocklossActiveFilter.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

function StocklossActiveFilter({
  fetchRequests,
  parameters,
  setParameters,
  moduleType
}) {
  const [form] = Form.useForm();
  const [modules, setModules] = useState([]);
  const [wholesalers, setWholesalers] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [cities, setCities] = useState([]);

  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getKkiModulesAndPharmacies() {
      try {
        const res_stockloss_modules = await axios_get('/companypages/modules');
        const result =
          res_stockloss_modules.data.length > 0
            ? res_stockloss_modules.data.filter(
                (module) => module.type === moduleType
              )
            : [];
        setModules(result);

        const res_wholesalers = await axios_get('/companypages/wholesaler');
        setWholesalers(res_wholesalers.data);
        const res_pharmacies = await axios_get('/companypages/pharmacies');
        setPharmacies(res_pharmacies.data);
        const citiesResponse = await axios_get('/cities');
        setCities(citiesResponse.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }

    getKkiModulesAndPharmacies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form
  const onFinish = (values) => {
    const { dateRange = [], ...valuesExceptDateRange } = values;
    let startDate, endDate;
    if (dateRange?.length > 0) {
      const convertedDatesString = dateRange.map((moment) => {
        const dateWithOffset = addLocalOffsetToDateObject(moment);

        const utcDateString = formatDateToUTCString(
          dateWithOffset,
          'yyyy-MM-dd'
        );
        return utcDateString;
      });
      if (convertedDatesString.length === 2) {
        [startDate, endDate] = convertedDatesString;
      }
    }

    const preparedFormValues = { startDate, endDate, ...valuesExceptDateRange };
    let filterObject = _.pickBy(preparedFormValues, _.identity);

    if (filterObject.requestId) {
      filterObject.requestId = Number(filterObject.requestId);
    }

    setParameters(filterObject);
    fetchRequests(filterObject);
  };

  const handleXLSButtonOnClick = async () => {
    try {
      await axios_post_download(
        '/companypages/export/pharmacy-stock-requests',
        {
          ...parameters,
          moduleType
        }
      );
    } catch (error) {
      // Read the blob data from the error response
      if (error.response?.data instanceof Blob) {
        const text = await error.response.data.text();
        const errorData = JSON.parse(text);
        getNotification({
          ...getErrorMessage({
            status: error.response.status,
            data: errorData
          })
        });
      } else {
        getNotification({
          ...getErrorMessage(error.response)
        });
      }
    }
  };

  //form parts
  /* Request No */
  function FormReqIdItem() {
    return (
      <Form.Item name="requestId" className="stockloss_active_filter_input">
        <Input
          type={'number'}
          className="kemi_form_input"
          placeholder="Başvuru No"
        />
      </Form.Item>
    );
  }
  /* Modul Name */
  function FormModuleIdItem() {
    return (
      <Form.Item name="moduleIds" className="stockloss_active_filter_input">
        <Select
          mode="multiple"
          allowClear
          showSearch
          maxTagCount="responsive"
          placeholder="Modül Adı"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          className="kemi_form_select"
          popupClassName="kemi_form_select_wide_dropdown"
        >
          {modules.length > 0 ? (
            modules.map((module) => {
              return (
                <Option
                  className="kemi_form_option"
                  key={module.moduleId}
                  value={module.moduleId}
                  name={module.name}
                >
                  {module.name}
                </Option>
              );
            })
          ) : (
            <Option disabled>
              <Spin />
            </Option>
          )}
        </Select>
      </Form.Item>
    );
  }
  /* Pharmacy Name */
  function FormPharmNameItem() {
    return (
      <Form.Item name="pharmacyGlns" className="stockloss_active_filter_input">
        <Select
          mode="multiple"
          allowClear
          showSearch
          placeholder="Eczane GLN - İsim"
          maxTagCount="responsive"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.gln.toString().toLowerCase().indexOf(input.toLowerCase()) >=
              0 ||
            option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >=
              0
          }
          className="kemi_form_select"
          popupClassName="kemi_form_select_wide_dropdown"
        >
          {pharmacies.length > 0 ? (
            pharmacies.map((pharmacy) => {
              return (
                <Option
                  key={pharmacy.gln}
                  value={pharmacy.gln}
                  gln={pharmacy.gln}
                  name={pharmacy.name}
                >
                  {pharmacy.gln} - {pharmacy.name}
                </Option>
              );
            })
          ) : (
            <Option disabled>
              <Spin />
            </Option>
          )}
        </Select>
      </Form.Item>
    );
  }
  /* Wholesaler Name */
  function FormWholesalerItem() {
    return (
      <Form.Item name="wholesalers" className="stockloss_active_filter_input">
        <Select
          mode="multiple"
          allowClear
          showSearch
          placeholder="Depo GLN - Depo İsim"
          maxTagCount="responsive"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.gln.toString().toLowerCase().indexOf(input.toLowerCase()) >=
              0 ||
            option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >=
              0
          }
          className="kemi_form_select"
          popupClassName="kemi_form_select_wide_dropdown"
        >
          {wholesalers.length > 0 ? (
            wholesalers.map((wholesaler) => {
              return (
                <Option
                  key={wholesaler.wholesalerId}
                  value={wholesaler.wholesalerId}
                  gln={wholesaler.gln}
                  name={wholesaler.name}
                >
                  {wholesaler.gln} - {wholesaler.name}
                </Option>
              );
            })
          ) : (
            <Option disabled>
              <Spin />
            </Option>
          )}
        </Select>
      </Form.Item>
    );
  }

  /* Request Status */
  function FormStatusItem() {
    return (
      <Form.Item name="statuses" className="stockloss_active_filter_input">
        <Select
          mode="multiple"
          allowClear
          showSearch
          placeholder="Durum"
          maxTagCount="responsive"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          className="kemi_form_select"
        >
          {statuses.length > 0 ? (
            statuses.map((status) => {
              return (
                <Option
                  key={status.id}
                  value={status.status}
                  name={status.text}
                >
                  {status.text}
                </Option>
              );
            })
          ) : (
            <Option disabled>
              <Spin />
            </Option>
          )}
        </Select>
      </Form.Item>
    );
  }

  return (
    <div className="stockloss_active_filter">
      <Form form={form} name="filter-form" onFinish={onFinish}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {/* Request No */}
          <FormReqIdItem />
          {/* Modul Name */}
          <FormModuleIdItem />
          {/* Pharmacy Name */}
          <FormPharmNameItem />
          {/* Wholesaler Name */}
          <FormWholesalerItem />
          {/* Pharmacy City */}
          <FormCityItem cities={cities} />
          {/* Request Status */}
          <FormStatusItem />
          {/* Date Range */}
          <Form.Item name="dateRange">
            <RangePicker
              format="DD.MM.YYYY"
              style={{ border: '1px solid #a29ece', borderRadius: '2px' }}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {/* Form Submit Item */}
          <Form.Item style={{ marginRight: '15px' }}>
            <Button className="kemi_form_btn_purple" htmlType="submit">
              Filtreleri Ara
            </Button>
          </Form.Item>
          {/* download xls */}
          <Button
            className="kemi_form_btn_purple"
            onClick={() => handleXLSButtonOnClick()}
          >
            .xls indir
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default StocklossActiveFilter;
