import { env } from '@src/env';
import { LOGGED, UNLOGGED } from '@src/redux/reducers/auth';
import { store } from '@src/store';
import axios from 'axios';
/**
 *@BUILD https://devkemi.integrio.io'
 *@DEV 'http://localhost:3000'
    const api = axios.create({
    baseURL: 'http://localhost:3000', // or https://devkemi.integrio.io',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.token}`
    },
});*/

/**
 * Axios Instance
 */
export const backendBaseUrl = env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: backendBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalConfig.url !== '/refresh-token'
    ) {
      return await api
        .post('/refresh-token', {}, { withCredentials: true })
        .then((res) => {
          const newToken = res.data.accessToken;
          store.dispatch(LOGGED(newToken));

          api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalConfig.headers['Authorization'] = `Bearer ${newToken}`;

          return api.request(originalConfig);
        })
        .catch((err) => {
          store.dispatch(UNLOGGED());
          return Promise.reject(err);
        });
    } else {
      return Promise.reject(error);
    }
  }
);

/**
 * Back-end Communication Requests v2
 */

/**
 * GET
 * @param {*} url
 * @returns response
 */

export const axios_get = (url) => {
  return api.get(`${url}`, {});
};
/**
 * POST
 * @param {*} url
 * @param {*} payload
 * @returns response
 */
export const axios_post = (url, payload) => {
  return api.post(`${url}`, payload, {});
};
/**
 * PATCH
 * @param {*} url
 * @param {*} payload
 * @returns response
 */
export const axios_patch = (url, payload) => {
  return api.patch(`${url}`, payload, {});
};
/**
 * DELETE
 * @param {*} url
 * @param {*} id
 * @returns response
 */
export const axios_delete = (url, id) => {
  return api.delete(`${url + id}`, {});
};
/**
 * DELETE request with body values
 * @param {*} url
 * @param {*} id
 * @param {*} body
 * @returns response
 */
export const axiosDeleteWithBody = (url, id, body) => {
  return axios.delete(`${backendBaseUrl}${url + id}`, {
    data: body
  });
};
/**
 * POST request without Authorization token
 * @param {*} url
 * @param {*} payload
 * @returns
 */
export const axios_post_public = (url, payload) => {
  return axios.post(`${backendBaseUrl}${url}`, payload);
};
/**
 * GET request without Authorization token
 * @param {*} url
 * @returns
 */
export const axios_get_public = (url) => {
  return axios.get(`${backendBaseUrl}${url}`);
};

/**
 * Not updated requests | Not ready for production
 */

export const AXIOS_GET_DOWNLOAD = async (url) => {
  api
    .get(url, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      responseType: 'blob'
    })
    .then((res) => {
      const { data, headers } = res;
      const fileName = headers['content-disposition'];
      const changedFileName = fileName.replace(/attachment; filename=/, '');
      //Here, when the JSON file is returned, the JSON.stringify Processing, other types of files do not need to be processed
      //const blob = new Blob([JSON.stringify(data)], ...)
      const blob = new Blob([data], { type: headers['content-type'] });
      let downloadButton = document.createElement('a');
      let imageUrl = window.URL.createObjectURL(blob);
      downloadButton.href = imageUrl;
      downloadButton.download = decodeURI(changedFileName);
      downloadButton.style.display = 'none';
      document.body.appendChild(downloadButton);
      downloadButton.click();
      downloadButton.parentNode.removeChild(downloadButton);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const axios_post_download = async (url, payload) => {
  try {
    const res = await api.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    });

    const { data, headers } = res;
    const fileName = headers['content-disposition'];
    const changedFileName = fileName.replace(/attachment; filename=/, '');
    const blob = new Blob([data], { type: headers['content-type'] });
    let downloadButton = document.createElement('a');
    let imageUrl = window.URL.createObjectURL(blob);
    downloadButton.href = imageUrl;
    downloadButton.download = decodeURI(changedFileName);
    downloadButton.style.display = 'none';
    document.body.appendChild(downloadButton);
    downloadButton.click();
    downloadButton.parentNode.removeChild(downloadButton);
  } catch (err) {
    throw err;
  }
};
