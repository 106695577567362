import { Layout, Menu } from 'antd';
import { useState } from 'react';
import { FaBars, FaHome, FaUsersCog } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import kemi_logo from '../../../assets/img/kemi_light_logo.png';
const { Sider } = Layout;

function AdminNavbar() {
  const { user } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(window.screen.width <= 1600);

  const [openKeys, setOpenKeys] = useState([]);
  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const menuItems = [
    {
      key: '/',
      icon: (
        <FaHome size={35} className="sidebar__menu-icon">
          <Link to="/" />
        </FaHome>
      ),
      label: <Link to="/">Ana Sayfa</Link>
    },
    {
      key: 'business-management',
      icon: <FaBars size={26} className="sidebar__menu-icon" />,
      label: 'İşlem Yönetimi',
      children: [
        {
          key: '/admin-company',
          label: <Link to="/admin-company">Modüller</Link>
        },
        {
          key: '/admin-kki-requests',
          label: <Link to="/admin-kki-requests">KKI Başvuruları</Link>
        },
        {
          key: '/admin-stock-requests',
          label: <Link to="/admin-stock-requests">Stok Zararı Başvuruları</Link>
        },
        {
          key: '/admin-pap-requests',
          label: <Link to="/admin-pap-requests">PAP Başvuruları</Link>
        }
      ]
    },
    {
      key: 'user-management',
      icon: <FaUsersCog size={26} className="sidebar__menu-icon" />,
      label: 'Kullanıcı Yönetimi',
      children: [
        {
          key: '/user-management-pharmacy',
          label: <Link to="/user-management-pharmacy">Eczane</Link>
        },
        {
          key: '/user-management-company',
          label: <Link to="/user-management-company">Firma</Link>
        },
        {
          key: '/user-management-admin',
          label: <Link to="/user-management-admin">Admin</Link>
        }
      ]
    }
  ];
  return (
    <Sider
      onBreakpoint={(broken) =>
        broken ? setCollapsed(true) : setCollapsed(false)
      }
      className="sidebar__"
      theme="light"
      width="15%"
      breakpoint="xxl"
      trigger={null}
      collapsed={collapsed}
    >
      {collapsed ? null : (
        <>
          <div className="sidebar__brand">
            <a className="flex items-center justify-center" href="/">
              <img src={kemi_logo} style={{ width: '35%' }} alt="navbar-logo" />
            </a>
          </div>
          <div className="__user_information">
            <h3>{user?.name + ' ' + user?.surname}</h3>
          </div>
        </>
      )}

      <Menu
        className="sidebar__menu"
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        selectedKeys={window.location.pathname}
        items={menuItems}
        mode="inline"
      />
    </Sider>
  );
}

export default AdminNavbar;
