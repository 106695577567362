import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import TableComponent from '@src/components/shared/TableComponent/TableComponent';
import { formatDateToTurkish } from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import { formatTurkishCurrency } from '@src/utils/numberFormats';
import { axios_post } from '@utils/rest_axios';
import { statusMap } from '@utils/staticJsonObjects/status';
import { useState } from 'react';
import AdminPharmacyRequestsFilter from '../AdminPharmacyRequestFilters/AdminPharmacyRequestsFilter';
import './AdminKKIActiveRequests.scss';
// import uniqBy from "lodash/uniqBy";

const detailCardType = 'admin';

function AdminKKIActiveRequests() {
  const [filterParameters, setFilterParameters] = useState({});
  const [requestRows, setRequestRows] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  // const [priceOptions, setPriceOptions] = useState([])
  const { getNotification } = useNotificationPopup();

  const fetchRequests = async (parameters) => {
    setRequestLoading(true);
    try {
      let updatedStatuses = [];

      if (
        parameters?.statuses?.includes('WAITING') &&
        !parameters?.statuses?.includes('PENDING')
      ) {
        updatedStatuses = parameters.statuses.map((status) =>
          status === 'WAITING' ? 'PENDING' : status
        );
      } else if (
        parameters?.statuses?.includes('WAITING') &&
        parameters?.statuses?.includes('PENDING')
      ) {
        updatedStatuses = parameters.statuses.filter((status) =>
          status === 'WAITING' ? false : true
        );
      } else {
        updatedStatuses = parameters?.statuses;
      }

      const response = await axios_post(`/admin/pharmacy-requests`, {
        ...parameters,
        offset: 0,
        moduleType: 'KKI',
        statuses: updatedStatuses
      });

      if (
        parameters?.statuses?.includes('WAITING') &&
        !parameters?.statuses?.includes('PENDING')
      ) {
        const requestsWithoutNonZeroPaymentAndPendingStatus =
          response.data.rows.filter(
            (row) =>
              !(row.totalPayment !== 0 && row.requestStatus === 'PENDING')
          );

        const updatedResponse = {
          ...response.data,
          count: requestsWithoutNonZeroPaymentAndPendingStatus.length,
          rows: requestsWithoutNonZeroPaymentAndPendingStatus
        };

        setRequestRows(updatedResponse);
      } else if (
        !parameters?.statuses?.includes('WAITING') &&
        parameters?.statuses?.includes('PENDING')
      ) {
        const requestsWithZeroPaymentAndPendingStatus =
          response.data.rows.filter(
            (row) =>
              !(row.totalPayment === 0 && row.requestStatus === 'PENDING')
          );

        const updatedResponse = {
          ...response.data,
          count: requestsWithZeroPaymentAndPendingStatus.length,
          rows: requestsWithZeroPaymentAndPendingStatus
        };

        setRequestRows(updatedResponse);
      } else {
        setRequestRows(response.data);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    } finally {
      setRequestLoading(false);
    }
  };

  const fetchRequestsWithPagination = async (_, paginationPage) => {
    setRequestLoading(true);
    try {
      let updatedStatuses = [];

      if (
        filterParameters?.statuses?.includes('WAITING') &&
        !filterParameters?.statuses?.includes('PENDING')
      ) {
        updatedStatuses = filterParameters.statuses.map((status) =>
          status === 'WAITING' ? 'PENDING' : status
        );
      } else if (
        filterParameters?.statuses?.includes('WAITING') &&
        filterParameters?.statuses?.includes('PENDING')
      ) {
        updatedStatuses = filterParameters.statuses.filter((status) =>
          status === 'WAITING' ? false : true
        );
      } else {
        updatedStatuses = filterParameters?.statuses;
      }

      const response = await axios_post(`/admin/pharmacy-requests`, {
        ...filterParameters,
        offset: paginationPage,
        moduleType: 'KKI',
        statuses: updatedStatuses
      });

      if (
        filterParameters?.statuses?.includes('WAITING') &&
        !filterParameters?.statuses?.includes('PENDING')
      ) {
        const requestsWithoutNonZeroPaymentAndPendingStatus =
          response.data.rows.filter(
            (row) =>
              !(row.totalPayment !== 0 && row.requestStatus === 'PENDING')
          );

        const updatedResponse = {
          ...response.data,
          count: requestsWithoutNonZeroPaymentAndPendingStatus.length,
          rows: requestsWithoutNonZeroPaymentAndPendingStatus
        };

        setRequestRows(updatedResponse);
      } else if (
        !filterParameters?.statuses?.includes('WAITING') &&
        filterParameters?.statuses?.includes('PENDING')
      ) {
        const requestsWithZeroPaymentAndPendingStatus =
          response.data.rows.filter(
            (row) =>
              !(row.totalPayment === 0 && row.requestStatus === 'PENDING')
          );

        const updatedResponse = {
          ...response.data,
          count: requestsWithZeroPaymentAndPendingStatus.length,
          rows: requestsWithZeroPaymentAndPendingStatus
        };

        setRequestRows(updatedResponse);
      } else {
        setRequestRows(response.data);
      }
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    } finally {
      setRequestLoading(false);
    }
  };

  const columns = [
    {
      title: 'Başvuru No',
      dataIndex: 'requestId',
      render: (text, row) => row.requestId
    },
    {
      title: 'Modül Adı',
      dataIndex: ['module', 'name']
    },
    {
      title: 'Eczane GLN',
      dataIndex: ['pharmacy', 'gln']
    },
    {
      title: 'Eczane Adı',
      dataIndex: ['pharmacy', 'name']
    },
    {
      title: 'Şehir',
      dataIndex: ['pharmacy', 'address', 'city', 'cityName']
    },
    {
      title: 'Başvuru Tarihi',
      dataIndex: 'applicationDate',
      render: (timestamp) => {
        return formatDateToTurkish(timestamp, true);
      }
    },
    {
      title: 'Tutar',
      dataIndex: 'totalPayment',
      render: (text) => formatTurkishCurrency(text, 2)
      // filters: priceOptions,
      // filterMode: 'tree',
      // onFilter: (value , record) => record.totalPayment === value,
    },
    {
      title: 'Ödeme Durumu',
      dataIndex: 'requestStatus',
      render: (text, row) => {
        return row.totalPayment === 0 && row.requestStatus === 'PENDING'
          ? 'Tamamlanmadı'
          : statusMap.get(text);
      }
    }
  ];

  return (
    <div>
      <AdminPharmacyRequestsFilter
        type={'KKI'}
        parameters={filterParameters}
        setParameters={setFilterParameters}
        fetchRequests={fetchRequests}
      />
      <TableComponent
        detailCardType={detailCardType}
        moduleType="KKI"
        page_type="ADMIN"
        columns={columns}
        fetchRequests={fetchRequestsWithPagination}
        data_arr={requestRows}
        requestLoading={requestLoading}
        filterParameters={filterParameters}
      />
    </div>
  );
}

export default AdminKKIActiveRequests;
